* {
  transition: width, height 1.2468163268140328s ease;
}

.brand-icon
{
  opacity: 1;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.6px;
  cursor: pointer;
}

ion-icon {
  min-height: 45px;
  min-inline-size: 35px;
}
ion-row {
  gap: 15px;
}

ion-footer {
  background-color: #fafafa;
}

.dark ion-footer {
  
  background-color: #4d4d4d;
}
.dark ion-footer  {
  
  color: #fafafa;
}

.l-grey-bcg {
  border-bottom: 3px solid #4d4d4d;
}

.dark .l-grey-bcg {
  border-bottom: 3px solid #4d4d4d;
}
ion-grid {
  width: 100%;
}
ion-list.cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

ion-input[type="text"] {
  border-bottom: 1px solid #4d4d4d;
}

.text-input {
  width: 100%;
}
ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-col h1 {
  font-weight: 900;
  text-transform: capitalize;
}

.extra-bold {
  font-weight: 900;
}

@media (max-width: 768px) {
  
  .small-to-grid {
    display: grid;
  }
}

form {
  max-width: 1024px;
  min-height: 50vh;

  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  top: 200px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  z-index: 10;
  background-color: white;
  border-radius: 25px;

}
form ion-input {
  width: 100% !important;
}

.shadow {
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  z-index: 9;
}